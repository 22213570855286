import Authenticated from 'src/components/Authenticated';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Navigate } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import BaseLayout from 'src/layouts/BaseLayout';
// import accountRoutes from './account';
import baseRoutes from './base';
// import studentRoutes from './student';
// import associatedRoutes from './associated';
// import memberRoutes from './member';
// import webRoutes from './web';
// import communicationsRoutes from './communications';
import configurationRoutes from './configuration';
// import reportsRoutes from './reports';
// import raffleRoutes from'./raffle';

// import { Navigate } from 'react-router-dom';



const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Empty = Loader(lazy(() => import('src/content/admin/empty/base')));
const Dashboard = Loader(lazy(() => import('src/content/admin/dashboard/base')));
const Calendar = Loader(lazy(() => import('src/content/admin/calendar/base')));
const CalendarEdit = Loader(lazy(() => import('src/content/admin/calendar/edit')));
const Clients = Loader(lazy(() => import('src/content/admin/clients/base')));
const Personal = Loader(lazy(() => import('src/content/admin/personal/base')));
const WebPage = Loader(lazy(() => import('src/content/admin/webpage/navigator')));
const Notification = Loader(lazy(() => import('src/content/admin/communications/notifications/base')));
const Reports = Loader(lazy(() => import('src/content/admin/reports/basics/base')));
const Events = Loader(lazy(() => import('src/content/admin/events/base')));
const PointOfSale = Loader(lazy(() => import('src/content/admin/sales/base')));
const Blank = Loader(lazy(() => import('src/content/admin/blank/base')));

const router = [
  // {
  // path:'raffle_ticket',
  // children: raffleRoutes
  // },
  // {
  //   path: 'bar_order',
  //   children: accountRoutes
  // },
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },
  // {
  //   path: 'bar_order',
  //   children: accountRoutes
  // },
  // {
  //   path: 'aim',
  //   element: (
  //     <Authenticated>
  //       <AccentHeaderLayout />
  //     </Authenticated>
  //   ),
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="student" replace />
  //     },
  //     {
  //       path: 'student',
  //       children: studentRoutes
  //     },
  //     {
  //       path: 'associated',
  //       children: associatedRoutes
  //     },
  //     {
  //       path: 'member',
  //       children: memberRoutes
  //     },
  //     {
  //       path: 'admin',
  //       children: adminRoutes
  //     }
  //   ]
  // },
  {
    path: 'admin',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: 'empty',
        element: <Empty/>
      },
      {
        path: 'dashboard',
        element: <Dashboard/>
      },
      {
        path: 'calendar',
        element: <Calendar/>
      },
      {
        path: 'calendar/lesson/:id',
        element: <CalendarEdit/>
      },
      {
        path: 'calendar/lesson',
        element: <CalendarEdit/>
      },
      {
        path: 'clients',
        element: <Clients/>
      },
      {
        path: 'personal',
        element: <Personal/>
      },
      {
        path: 'web',
        element:<WebPage/>
      },
      {
        path: 'communications',
        element:<Notification/>
      },
      {
        path: 'gifts',
        element: <Blank/>
      },
      {
        path: 'reports',
        element:<Reports/>
      },
      {
        path: 'events',
        element: <Events/>
      },
      {
        path: 'configuration',
        children:configurationRoutes
      },
      {
        path: 'sales',
        element: <PointOfSale/>
      }
      // {
      //   path:'employeeOpe',
      //   children:employeeOpeRoutes
      // }
    ]
  }
];

export default router;
