import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Admin

// const Associates = Loader(lazy(() => import('src/content/admin/associates')));
// const Members = Loader(lazy(() => import('src/content/admin/members')));
// const Students = Loader(lazy(() => import('src/content/member/students')));
const Dashboard = Loader(lazy(() => import('src/content/admin/dashboard/base')));
const RolesBase= Loader(lazy(() => import('src/content/admin/roles/base')));
const RolesNew= Loader(lazy(() => import('src/content/admin/roles/new')));
const RolesEdit= Loader(lazy(() => import('src/content/admin/roles/edit')));
const PlansBase = Loader(lazy(() => import('src/content/admin/configuration/plans/base')));
const CustomRoomBase = Loader(lazy(() => import('src/content/admin/configuration/custom-room/base')));
const Blank = Loader(lazy(() => import('src/content/admin/blank/base')));


const adminRoutes = [
  {
    path: '/',
    element: <Navigate to="disciplines" replace />
  },
  {
    path: 'rooms',
    element: <Dashboard />
  },
  {
    path: 'disciplines',
    element: <Dashboard />
  },
  {
    path: 'plans',
    element: <PlansBase/>
  },
  {
    path: 'payments',
    element: <Dashboard />
  },
  {
    path: 'set-values',
    element: <Dashboard />
  },
  {
    path: 'establishment',
    element: <Dashboard />
  },
  {
    path: 'roles',
    element: <RolesBase />
  },
  {
    path: 'roles/new',
    element: <RolesNew />
  },
  {
    path: 'roles/edit',
    element: <RolesEdit />
  },
  {
    path: 'messages',
    element: <Dashboard />
  },
  {
    path: 'web',
    element: <Dashboard />
  },
  {
    path: 'cashier-balancing',
    element: <Dashboard />
  },
  {
    path: 'custom-room',
    element: <CustomRoomBase />
  },
  {
    path: 'credentials',
    element: <Blank />
  },
  {
    path: 'variables',
    element: <Blank />
  },
];

export default adminRoutes;